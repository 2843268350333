import { TitleCasePipe } from '@angular/common';
import { SourceModelFragment, SourceTypeEnum } from '@spog-ui/graphql/types';
import { TreeSelectOption } from '@spog-ui/shared/components';
import { groupBy } from 'lodash';

const ICON_BY_SOURCE_TYPE = {
  [SourceTypeEnum.BRIDGE485_METRIC]: 'settings_input_antenna',
  [SourceTypeEnum.ASSET]: 'developer_board',
  [SourceTypeEnum.ASSET_COLLECTION]: 'list_alt',
  [SourceTypeEnum.LIGHTING_ZONE]: 'blur_circular',
  [SourceTypeEnum.DEPARTMENT]: 'account_tree',
  [SourceTypeEnum.GAS_METER]: 'valve',
  [SourceTypeEnum.SENSE420_SENSOR]: 'settings_input_antenna',
  [SourceTypeEnum.THERMOSTAT]: 'spog_thermostat',
  [SourceTypeEnum.VIRTUAL_SENSOR]: 'settings_input_antenna',
  [SourceTypeEnum.WATER_METER]: 'water_pump',
};

export interface ComplexValueModel {
  name: string;
  units: string;
}

export interface SourceInternalModel {
  id: string;
  name: string;
  type: SourceTypeEnum;
  readingUnits: string;
  siteId: string;
  complexValues: ComplexValueModel[];
}

export function toSourceInternalModelFromGQL(
  source: SourceModelFragment,
): SourceInternalModel {
  return {
    id: source.id,
    name: source.name,
    type: source.type,
    readingUnits: source.readingUnits,
    siteId: source.siteId,
    complexValues: source.complexValueDefinitions?.complexValues ?? [],
  };
}

export function toOptionFromSource(source: SourceInternalModel): TreeSelectOption {
  return {
    value: source.id,
    name: source.name.includes('Assets')
      ? source.name.replace('Assets', 'Equipment')
      : source.name,
    checked: false,
    ancestor: '',
    ...(source.type === SourceTypeEnum.THERMOSTAT
      ? { svgIcon: ICON_BY_SOURCE_TYPE[source.type] }
      : { icon: ICON_BY_SOURCE_TYPE[source.type] }),
  };
}

export function toOptionsFromSources(sources: SourceInternalModel[]): TreeSelectOption[] {
  const groupsByType = groupBy(sources, 'type');

  const optionsList: TreeSelectOption[] = [];

  Object.entries(groupsByType).forEach(([type, sourceList]) => {
    const parentOptionForType: TreeSelectOption = {
      value: type,
      checked: false,
      name:
        type !== 'ASSET_COLLECTION'
          ? type !== 'ASSET'
            ? `${new TitleCasePipe().transform(type.replace('_', ' '))}s`
            : 'Equipment'
          : 'Equipment Groups',
      ancestor: '',
    };

    optionsList.push(parentOptionForType);

    optionsList.push(
      ...sourceList.map(source => {
        const option = toOptionFromSource(source);
        option.ancestor = type;

        return option;
      }),
    );
  });

  return optionsList;
}
