import { NgModule } from '@angular/core';
import { ServiceWorkerModule as NgServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { AppUpdatesApiEffects, AppUpdatesEffects } from './effects';

@NgModule({
  imports: [
    NgServiceWorkerModule.register('ngsw-worker.js', {
      registrationStrategy: 'registerImmediately',
    }),
    EffectsModule.forFeature([AppUpdatesEffects, AppUpdatesApiEffects]),
  ],
})
export class AppUpdatesModule {}
