import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'spog-subscription-banner',
  template: `
    @if (activeSite$ | async; as activeSite) { @if (activeSite.subscriptionInfo) { @if
    (activeSite.subscriptionInfo.expiredAlready) {
    <div class="subscriptionBanner">
      This site's subscription expired on
      {{ activeSite.subscriptionInfo.expiresOn | date }}. <br />
      <a href="https://support.synapsewireless.com" target="_blank">Contact</a> Support to
      renew.
    </div>
    } @else { @if (activeSite.subscriptionInfo.expiresSoon) {
    <div class="subscriptionBanner">
      This site's subscription expires on
      {{ activeSite.subscriptionInfo.expiresOn | date }}. <br />
      <a href="https://support.synapsewireless.com" target="_blank">Contact</a> Support to
      renew.
    </div>
    } }
    <ng-template #expiresSoon>
      @if (activeSite.subscriptionInfo.expiresSoon) {
      <div class="subscriptionBanner">
        This site's subscription expires on
        {{ activeSite.subscriptionInfo.expiresOn | date }}. <br />
        <a href="https://support.synapsewireless.com" target="_blank">Contact</a> Support
        to renew.
      </div>
      }
    </ng-template>
    } }
  `,
  styles: [
    `
      .subscriptionBanner {
        background-color: var(--color-warn-500);
        text-align: center;
        width: 100%;
        min-height: 40px;
        line-height: 24px;
        padding: 8px;
      }

      a {
        color: inherit;
        cursor: pointer;
      }

      @media screen and (min-width: 640px) {
        br {
          display: none;
        }
      }
    `,
  ],
  standalone: true,
  imports: [NgIf, AsyncPipe, DatePipe],
})
export class SubscriptionBannerComponent {
  activeSite$ = this.store.select(CoreState.selectSiteForActiveSiteId);

  constructor(private store: Store, private sanitizer: DomSanitizer) {}
}

@Pipe({
  name: 'safeurl',
  standalone: true,
})
export class SafeurlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(value: any) {
    return this.domSanitizer.bypassSecurityTrustUrl(value);
  }
}
