import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  FeatureGuardService,
  FeatureGuardData,
  GoHomeGuardService,
  GoToMapGuardService,
} from '@spog-ui/shared/services';
import { AnonymousGuardService } from '@spog-ui/current-user/feature';
import { AuthGuard } from '@auth0/auth0-angular';
import {
  ActiveSiteIdResolver,
  SiteGuardService,
} from '@spog-ui/shared/state/site/site-guard';
import { PrefixlessSiteGuardService } from '@spog-ui/shared/state/site/site-guard';
import { SceneApplication } from '@spog-ui/shared/models/scenes';
import { PageNotFoundComponent } from '@spog-ui/modules/page-not-found';
import { LandingPageComponent } from './components/landing-page';
import { Products } from '@spog-ui/graphql/types';
import { IfUserCanGuard } from '@spog-ui/current-user/permissions';

export const routes: Routes = [
  {
    path: 'landing',
    component: LandingPageComponent,
    canActivate: [AnonymousGuardService],
  },
  {
    path: 'user',
    loadChildren: () => import('@spog-ui/site-selector').then(m => m.SiteSelectorModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('@spog-ui/admin/feature').then(m => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin-organization/:activeOrgId',
    loadChildren: () =>
      import('@spog-ui/admin-organization/feature').then(m => m.AdminOrganizationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'site/:activeSiteId',
    canActivate: [SiteGuardService],
    resolve: {
      activeSiteIdFromUrl: ActiveSiteIdResolver,
    },
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'map',
        loadChildren: () => import('@spog-ui/map').then(m => m.MapModule),
        canActivate: [IfUserCanGuard],
        data: { permissions: ['ssc:siteAdmin', 'ssc:siteViewer', 'ssc:sceneUser'] },
      },
      {
        path: 'go-to-map',
        children: [],
        canActivate: [GoToMapGuardService],
      },
      {
        path: 'lighting/scenes',
        loadChildren: () => import('@spog-ui/scenes').then(m => m.ScenesModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <
          FeatureGuardData & {
            application: SceneApplication;
          }
        >{
          permissions: [
            'ssc:siteAdmin',
            'ssc:siteViewer',
            'ssc:sceneUser',
            'ssc:schedule-and-scene',
          ],
          requiredProducts: [Products.ILLUMINATE],
          application: SceneApplication.LIGHTING,
        },
      },
      {
        path: 'climate/scenes',
        loadChildren: () => import('@spog-ui/scenes').then(m => m.ScenesModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <
          FeatureGuardData & {
            application: SceneApplication;
            translatableTokens: Record<'add' | 'edit' | 'view', string>;
          }
        >{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.CLIMATE],
          application: SceneApplication.CLIMATE,
          translatableTokens: {
            add: 'scenes.addClimateScene',
            edit: 'scenes.editClimateScene',
            view: 'scenes.climateScenes',
          },
        },
      },
      {
        path: 'power/comparison',
        loadChildren: () => import('@spog-ui/power').then(m => m.PowerModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.SENSE_POWER, Products.ILLUMINATE_POWER],
        },
      },
      {
        path: 'schedule',
        loadChildren: () => import('@spog-ui/schedule').then(m => m.ScheduleModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer', 'ssc:schedule-and-scene'],
          requiredProducts: [Products.SENSE, Products.ILLUMINATE, Products.CLIMATE],
        },
      },
      {
        path: 'import',
        loadChildren: () => import('@spog-ui/import').then(m => m.ImportModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin'],
          requiredProducts: [Products.SENSE, Products.ILLUMINATE, Products.CLIMATE],
        },
      },
      {
        path: 'sense/industrial-sensors',
        loadChildren: () =>
          import('@spog-ui/indie-sensors').then(m => m.IndieSensorsModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.SENSE],
        },
      },
      {
        path: 'sense/bridge-485s',
        loadChildren: () => import('@spog-ui/bridge-485s').then(m => m.Bridge485sModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.SENSE],
        },
      },
      {
        path: 'site-controllers',
        loadChildren: () =>
          import('@spog-ui/site-controllers').then(m => m.SiteControllersModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.REMOTE_ACCESS],
        },
      },
      {
        path: 'power/insights',
        loadChildren: () =>
          import('@spog-ui/power-insights').then(m => m.PowerInsightsModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.SENSE_POWER],
        },
      },
      {
        path: 'power/utility-services',
        loadChildren: () =>
          import('@spog-ui/utility-services').then(m => m.UtilityServicesModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.SENSE_POWER],
        },
      },
      {
        path: 'power/departments',
        loadChildren: () => import('@spog-ui/departments').then(m => m.DepartmentsModule),
        canActivate: [IfUserCanGuard],
        data: { permissions: ['ssc:siteAdmin', 'ssc:siteViewer'] },
      },
      {
        path: 'power/equipment',
        loadChildren: () => import('@spog-ui/equipment').then(m => m.EquipmentModule),
        canActivate: [IfUserCanGuard],
        data: { permissions: ['ssc:siteAdmin', 'ssc:siteViewer'] },
      },
      {
        path: 'lighting/alarms',
        loadChildren: () => import('@spog-ui/alarms').then(m => m.AlarmsModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.ILLUMINATE],
        },
      },
      {
        path: 'lighting/zones',
        loadChildren: () =>
          import('@spog-ui/modules/light-zones').then(m => m.LightZonesModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.ILLUMINATE],
        },
      },
      {
        path: 'sense/triggers',
        loadChildren: () => import('@spog-ui/triggers').then(m => m.TriggersModule),
        canActivate: [IfUserCanGuard],
        data: { permissions: ['ssc:siteAdmin', 'ssc:siteViewer'] },
      },
      {
        path: 'climate',
        loadChildren: () =>
          import('@spog-ui/climate-devices').then(m => m.ClimateDevicesModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.CLIMATE],
        },
      },
      {
        path: 'charting',
        loadChildren: () => import('@spog-ui/charting').then(m => m.ChartingModule),
        canActivate: [IfUserCanGuard],
        data: {
          permissions: ['ssc:siteAdmin', 'ssc:siteViewer'],
          requiredProducts: [Products.SENSE_POWER],
        },
      },
      {
        path: 'scene-views',
        loadChildren: () => import('@spog-ui/scene-views').then(m => m.SceneViewsModule),
        canActivate: [FeatureGuardService, IfUserCanGuard],
        data: <FeatureGuardData>{
          permissions: [
            'ssc:siteAdmin',
            'ssc:siteViewer',
            'ssc:sceneUser',
            'ssc:schedule-and-scene',
          ],
          // TODO: switch to SCENE_VIEWS when feature is added
          requiredProducts: [Products.ILLUMINATE],
        },
      },
      {
        path: '',
        pathMatch: 'full',
        children: [],
        canActivate: [GoHomeGuardService],
      },
      {
        path: '404',
        pathMatch: 'full',
        component: PageNotFoundComponent,
      },
      {
        path: '**',
        redirectTo: '404',
      },
    ],
  },
  {
    path: 'release-notes',
    loadChildren: () => import('@spog-ui/release-notes').then(m => m.ReleaseNotesModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/landing',
  },
  {
    path: '404',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [PrefixlessSiteGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class LightingRoutingModule {}
