import { Component, OnDestroy, ViewChild, effect, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { SuiAnimateFab } from '@spog-ui/shared/components';

import * as Highcharts from 'highcharts/highstock';
import HC_data from 'highcharts/modules/export-data';
import HC_exporting from 'highcharts/modules/exporting';
import HC_noData from 'highcharts/modules/no-data-to-display';
import offlineExporting from 'highcharts/modules/offline-exporting';
import accessibility from 'highcharts/modules/accessibility';

Highcharts.setOptions({
  chart: {
    styledMode: true,
  },
  credits: {
    enabled: false,
  },
  lang: {
    thousandsSep: ',',
  },
  accessibility: {
    description: 'Multi-source, multi-unit telemetry charting',
  },
});

HC_exporting(Highcharts);
HC_data(Highcharts);
HC_noData(Highcharts);
offlineExporting(Highcharts);
accessibility(Highcharts);

import { ChartingPageActions } from '@spog-ui/charting/actions';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SourcesStore } from '../../state/sources.store';
import { SelectedSourcesStore } from '../../state/selected-sources.store';
import { DateTime } from 'luxon';
import { MatSelectChange } from '@angular/material/select';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'spog-charting-page',
  template: `
    @if (sourcesStore.loadingSources()) {
    <sui-spinner/>
    } @else { @if (sourcesStore.error()) {
    <sui-error-card [item]="'sources'"/>
    } @else {
      <mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav [mode]="'over'" position="end">
    <div class="closeLayer">
    <mat-icon aria-hidden="false" aria-label="Close Optiosn" (click)="sidenav.toggle()"
                  >close</mat-icon
                  >
    </div>
    <div class="controls">

      <spog-date-control
        (dateRangeSelected)="onDateRangeSelected($event)"
      ></spog-date-control>

      <mat-form-field>
        <mat-label>Bucket Size</mat-label>
        <mat-select name="interval" [value]="'FIFTEEN_MINUTES'" (selectionChange)="onIntervalChange($event)">
          <mat-option [value]="'FIFTEEN_MINUTES'">15 Min</mat-option>
          <mat-option [value]="'HOUR'">Hour</mat-option>
          <mat-option [value]="'WEEK'">Week</mat-option>
          <mat-option [value]="'MONTH'">Month</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <sui-tree-select
        title="Sources"
        [options]="sourcesStore.sourcesAsOptions()"
        (optionSelected)="onSelected($event)"
        (optionDeselected)="onDeselected($event)"
        
      />
    </mat-sidenav>
    <mat-sidenav-content>
    <highcharts-chart
      class="chart"
      [Highcharts]="Highcharts"
      [constructorType]="'stockChart'"
      [options]="chartOptions"
    ></highcharts-chart>
    </mat-sidenav-content>
  </mat-sidenav-container>
    }}
  `,
  styles: [
    `
      :host {
        display: flex;
        margin: 0 auto;
        width: 100%;
        padding: 24px 24px 96px;
      }

      mat-sidenav-container {
        width: 100%;
      }

      spog-date-control {
        padding-right: 16px;
      }

      sui-tree-select {
        height: 400px;
        display: block;
      }

      sui-spinner {
        width: 50px;
        display: block;
        margin: 60px auto 0;
      }

      .chart {
        width: 100%;
        height: 700px;
        display: block;
        padding-left: 8px;
      }

      .closeLayer {
        display: block;
        text-align: right;
      }

      .closeLayer mat-icon {
        margin-top: 8px;
        margin-right: 8px;
      }

      .controls {
        display: block;
        padding-left: 16px;
        padding-right: 16px;
      }

      ::ng-deep .highcharts-button {
        fill: none;
        stroke: none;
      }

      ::ng-deep .highcharts-button text {
        fill: var(--color-500);
        font-size: 14px;
      }

      ::ng-deep .highcharts-range-selector-buttons .highcharts-button {
        fill: var(--mat-tree-container-background-color);
      }

      ::ng-deep .highcharts-range-selector-buttons .highcharts-button-pressed text {
        fill: var(--color-500);
      }

      ::ng-deep .highcharts-range-selector-buttons .highcharts-button-hover text {
        fill: var(--color-500);
      }

      ::ng-deep .highcharts-range-selector-buttons .highcharts-button-disabled {
        fill: var(--color-foreground-disabled-button);
      }

      ::ng-deep .highcharts-axis-title {
        font-size: 1em;
      }

      ::ng-deep .highcharts-menu {
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
          0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      }

      :host ::ng-deep .mat-drawer-backdrop.mat-drawer-shown {
        background-color: rgb(from var(--color-500) r g b / 30%);
      }

      :host ::ng-deep .mat-mdc-select-arrow-wrapper {
        padding-right: 8px;
      }

      :host ::ng-deep .mdc-text-field {
        padding-left: 0px;
      }

      mat-form-field {
        width: 170px;
      }
    `,
  ],
  animations: [SuiAnimateFab],
  providers: [SourcesStore, SelectedSourcesStore],
})
export class ChartingPageComponent implements OnDestroy {
  readonly sourcesStore = inject(SourcesStore);
  readonly selectedSourcesStore = inject(SelectedSourcesStore);

  @ViewChild('sidenav') public sidenav: MatSidenav;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options = {
    lang: {
      rangeSelectorZoom: 'Range:',
      noData: 'No data loaded',
    },
    exporting: {
      scale: 2,
      sourceWidth: 1200,
      sourceHeight: 700,
      fallbackToExportServer: false,
      buttons: {
        menuButton: {
          text: 'EXPORT',
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadPDF',
            'separator',
            'downloadCSV',
          ],
          y: -5,
        },
        filterButton: {
          text: 'OPTIONS',
          onclick: () => {
            this.sidenav.toggle();
          },
          y: -5,
        },
        contextButton: {
          enabled: false,
        },
      },
    },
    rangeSelector: {
      selected: 1,
      verticalAlign: 'top',
      inputEnabled: false,
      buttons: [
        {
          type: 'hour',
          text: '1h',
          count: 1,
          title: 'View 1 hour',
        },
        {
          type: 'day',
          count: 1,
          text: '1d',
          title: 'View 1 day',
        },
        {
          type: 'week',
          text: '1w',
          count: 1,
          title: 'View 1 week',
        },
        {
          type: 'month',
          text: '1m',
          count: 1,
          title: 'View 1 month',
        },
        {
          type: 'year',
          text: '1y',
          count: 1,
          title: 'View 1 year',
        },
      ],
    },
    title: {
      text: this.selectedSourcesStore.title(),
      align: 'left',
    },
    xAxis: {},
    yAxis: [],
    tooltip: {
      shared: true,
    },
    legend: {
      enabled: true,
      align: 'center',
      x: 0,
      verticalAlign: 'top',
      y: 0,
      layout: 'vertical',
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend?.backgroundColor || // theme
        'rgba(255,255,255,0.25)',
      labelFormatter: function () {
        return `${this.name} (${(this as any).yAxis.options.title.text})`;
      },
    },
    plotOptions: {
      series: {
        showInNavigator: true,
      },
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              floating: false,
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
              x: 0,
              y: 0,
            },
            yAxis: [
              {
                labels: {
                  align: 'right',
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                labels: {
                  align: 'left',
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                visible: false,
              },
            ],
          },
        },
      ],
    },
  };

  form = new UntypedFormGroup({
    sources: new UntypedFormControl([]),
  });

  constructor(
    readonly store: Store,
    public breakpointObserver: BreakpointObserver,
    readonly snackbar: MatSnackBar,
  ) {
    effect(() => {
      const chart = Highcharts.charts[Highcharts.charts.length - 1];

      // Angular figures out which effects to fire based on which signals
      // are called.   Apparently, it can't tell the calls in update() below
      // happen, presumably due to how compilation deals with the ? operator.
      // These explicit calls ensures the effect fires correctly.
      const series = this.selectedSourcesStore.series();
      const yAxes = this.selectedSourcesStore.yAxes();
      const title = this.selectedSourcesStore.title();

      chart?.update(
        {
          series,
          yAxis: yAxes,
          title: {
            text: title,
          },
        },
        true,
        true,
      );

      // Reset the x-axis to display full loaded range
      if (chart?.xAxis.length) {
        chart?.xAxis[0].setExtremes();
      }
    });

    effect(() => {
      const chart = Highcharts.charts[Highcharts.charts.length - 1];
      if (this.selectedSourcesStore.loadingSourceData()) {
        chart?.showLoading();
      } else {
        chart?.hideLoading();
      }
    });

    effect(() => {
      const error = this.selectedSourcesStore.error();

      if (error) {
        snackbar.open(`Error: ${error}. Please try again`, 'Dismiss', {
          duration: 7_500,
        });
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(ChartingPageActions.enterAction());

    this.breakpointObserver
      .observe(['(min-width: 450px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.selectedSourcesStore.setUseLongTitle(true);
        } else {
          this.selectedSourcesStore.setUseLongTitle(false);
        }
      });
  }

  ngOnDestroy(): void {
    const chart = Highcharts.charts[Highcharts.charts.length - 1];
    chart?.destroy();
  }

  onSelected($event: any) {
    this.selectedSourcesStore.addSelectedSource(this.sourcesStore.entityMap()[$event]);
  }

  onDeselected($event: any) {
    this.selectedSourcesStore.removeSelectedSource($event);
  }

  onDateRangeSelected(event: [DateTime, DateTime]) {
    this.selectedSourcesStore.setDateRange(event);
  }

  onIntervalChange(event: MatSelectChange) {
    this.selectedSourcesStore.setInterval(event.value);
  }
}
