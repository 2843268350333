import { Inject, Injectable, Optional } from '@angular/core';
import { Action } from '@ngrx/store';
import { ComponentStore } from '@ngrx/component-store';
import { ofType } from '@ngrx/effects';
import { EMPTY, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  reducer,
  initialState,
  Shape,
  selectMode,
  selectColor,
  selectSliderMaxValue,
  selectRedSliderValue,
  selectGreenSliderValue,
  selectBlueSliderValue,
  selectAmberSliderValue,
  selectWhiteSliderValue,
  selectIsDisabled,
  selectValue,
  selectHexInputValue,
  selectHexInputIsValid,
  selectAppSupportsColorPalette,
  selectIsLoadingColorPalette,
  selectColorPalette,
  selectColorIndex,
} from './color-picker.reducer';
import * as ColorPickerActions from './color-picker.actions';
import * as ColorPickerApiActions from './color-picker-api.actions';
import { ColorPaletteService } from './color-palette.service';
import { flattenPalette, isUsingFastColor } from './color-picker.models';
import { PromptComponent } from '@sui/prompt';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ColorPickerStore extends ComponentStore<Shape> {
  private actions$ = new Subject<Action>();

  mode$ = this.select(selectMode);
  color$ = this.select(selectColor);
  value$ = this.select(selectValue);
  sliderMaxValue$ = this.select(selectSliderMaxValue);
  redSliderValue$ = this.select(selectRedSliderValue);
  greenSliderValue$ = this.select(selectGreenSliderValue);
  blueSliderValue$ = this.select(selectBlueSliderValue);
  amberSliderValue$ = this.select(selectAmberSliderValue);
  whiteSliderValue$ = this.select(selectWhiteSliderValue);
  isDisabled$ = this.select(selectIsDisabled);
  hexInputValue$ = this.select(selectHexInputValue);
  hexInputIsValid$ = this.select(selectHexInputIsValid);
  appSupportsColorPalette$ = this.select(selectAppSupportsColorPalette);
  isLoadingColorPalette$ = this.select(selectIsLoadingColorPalette);
  colorPalette$ = this.select(selectColorPalette);
  isUsingFastColor$ = this.select(shape =>
    isUsingFastColor(shape.colorPalette, shape.color),
  );
  touched$ = this.actions$.pipe(
    ofType(
      ColorPickerActions.updateColorChannelFromSliders,
      ColorPickerActions.userInputsHexValue,
      ColorPickerActions.userBlursHexInput,
      ColorPickerActions.userAppliesColorSwatch,
      ColorPickerActions.userReordersColorInColorSwatch,
      ColorPickerActions.userReplacesColorInColorSwatch,
      ColorPickerActions.userDeletesColorInColorSwatch,
      ColorPickerActions.userCreatesColorSwatch,
    ),
    // I am intentionally using an empty arrow function here to
    // make the type of this be Observable<void>
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    map(() => {}),
  );
  change$ = this.actions$.pipe(
    ofType(
      ColorPickerActions.updateColorChannelFromSliders,
      ColorPickerActions.userInputsHexValue,
      ColorPickerActions.userAppliesColorSwatch,
    ),
    withLatestFrom(this.value$),
    map(([_, value]) => value),
  );

  constructor(
    @Inject(ColorPaletteService)
    @Optional()
    readonly colorPaletteService: ColorPaletteService | null,
    readonly dialog: MatDialog,
  ) {
    super({
      ...initialState,
      appSupportsColorPalette: Boolean(colorPaletteService),
    });

    this.listenForColorSwatchReplace();
    this.listenForColorSwatchDelete();
    this.listenForColorPaletteChanges();
    this.loadColorPalette();
  }

  /**
   * Listens for changes to the color palette and persists them to the server.
   */
  private listenForColorPaletteChanges = this.effect(() => {
    const colorPaletteService = this.colorPaletteService;

    if (!colorPaletteService) return EMPTY;

    return this.actions$.pipe(
      ofType(
        ColorPickerActions.userCreatesColorSwatch,
        ColorPickerActions.userReplacesColorInColorSwatch,
        ColorPickerActions.userDeletesColorInColorSwatch,
        ColorPickerActions.userReordersColorInColorSwatch,
      ),
      withLatestFrom(this.colorPalette$),
      switchMap(([action, colorPalette]) => {
        return colorPaletteService.save(colorPalette).pipe(
          map(() => ColorPickerApiActions.saveColorPaletteSuccess(colorPalette)),
          catchError(error => {
            if (typeof error !== 'string') {
              throw new Error(
                '@sui/color-picker expects the ColorPaletteService implementation to coerce errors to strings',
              );
            }

            return of(ColorPickerApiActions.saveColorPaletteFailure(error));
          }),
        );
      }),
      tap((action: Action) => this.dispatch(action)),
    );
  });

  private listenForColorSwatchReplace = this.effect(() => {
    return this.actions$.pipe(
      ofType(ColorPickerActions.userStartsReplacingColorSwatch),
      map(action => {
        return action.index < 4
          ? ColorPickerActions.userChangesFastColorInColorSwatch(action.index, 'Replace')
          : ColorPickerActions.userReplacesColorInColorSwatch(action.index);
      }),
      tap((action: Action) => this.dispatch(action)),
    );
  });

  private listenForColorSwatchDelete = this.effect(() => {
    return this.actions$.pipe(
      ofType(ColorPickerActions.userStartsDeletingColorSwatch),
      map(action => {
        return action.index < 4
          ? ColorPickerActions.userChangesFastColorInColorSwatch(action.index, 'Remove')
          : ColorPickerActions.userDeletesColorInColorSwatch(action.index);
      }),
      tap((action: Action) => this.dispatch(action)),
    );
  });

  private listenForFastColorChange = this.effect(() => {
    return this.actions$.pipe(
      ofType(ColorPickerActions.userChangesFastColorInColorSwatch),
      switchMap(action => {
        const dialogRef = this.dialog.open(PromptComponent, {
          data: {
            title: `${action.change} color swatch?`,
            description: `Are you sure you want to ${action.change.toLowerCase()} this color swatch? It may effect existing scenes.`,
            cancelLabel: 'Cancel',
            confirmLabel: `${action.change.toUpperCase()}`,
            confirmColor: 'primary',
          },
        });

        return dialogRef.afterClosed().pipe(
          switchMap((didConfirm: boolean) => {
            if (didConfirm) {
              return action.change === 'Remove'
                ? of(ColorPickerActions.userDeletesColorInColorSwatch(action.index))
                : of(ColorPickerActions.userReplacesColorInColorSwatch(action.index));
            } else {
              return EMPTY;
            }
          }),
        );
      }),
      filter(action => !!action),
      tap((action: Action) => this.dispatch(action)),
    );
  });

  /**
   * Effect to load the color palette
   */
  private loadColorPalette = this.effect(() => {
    const colorPaletteService = this.colorPaletteService;

    if (!colorPaletteService) return EMPTY;

    return colorPaletteService.load().pipe(
      map(colorPalette =>
        ColorPickerApiActions.loadColorPaletteSuccess(flattenPalette(colorPalette)),
      ),
      catchError(error => {
        if (typeof error !== 'string') {
          throw new Error(
            '@sui/color-picker expects the ColorPaletteService implementation to coerce errors to strings',
          );
        }

        return of(ColorPickerApiActions.loadColorPaletteFailure(error));
      }),
      tap((action: Action) => this.dispatch(action)),
    );
  });

  private listenForColorPaletteWebSocketMessages = this.effect(() => {
    const colorPaletteService = this.colorPaletteService;

    if (!colorPaletteService) return EMPTY;

    return colorPaletteService.update().pipe(
      map(colorPalette =>
        ColorPickerApiActions.updateColorPaletteFromWs(flattenPalette(colorPalette)),
      ),
      tap((action: Action) => this.dispatch(action)),
    );
  });

  get value() {
    return this.get(selectValue);
  }

  get mode() {
    return this.get(selectMode);
  }

  get isDisabled() {
    return this.get(selectIsDisabled);
  }

  get hexInputIsValid() {
    return this.get(selectHexInputIsValid);
  }

  get colorIndex() {
    return this.get(selectColorIndex);
  }

  dispatch(action: Action) {
    this.setState(state => reducer(state, action));
    this.actions$.next(action);
  }
}
