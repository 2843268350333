import { Component, Input, OnDestroy, Output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SwatchRendererService } from './swatch-renderer.service';
import { ColorPickerStore } from './color-picker.store';
import * as ColorPickerActions from './color-picker.actions';
import { Color, ColorPickerMode } from './color-picker.models';

@Component({
  selector: 'clr-color-picker',
  template: `
    <clr-color-sliders></clr-color-sliders>
    <clr-hex-input></clr-hex-input>
    <clr-color-preview
      draggable="true"
      (dragstart)="onDragStart($event)"
    ></clr-color-preview>
    <clr-color-palette *ngIf="appSupportsColorPalette$ | async"></clr-color-palette>
    <div class="footer">
      <div class="waitWarning">
        Note: When changing the top 4 palette colors in systems with DALI2 drivers, wait 5
        minutes after saving changes before applying any color scene.
      </div>
      <div
        class="warnIfNotFastColor"
        *ngIf="(isUsingFastColor$ | async) === false && warnIfNotUsingFastColor"
      >
        For systems with DALI2 drivers, use a color in the top 4 palette slots.
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: grid;
        grid-template-areas:
          'sliders preview'
          'sliders palette'
          'input palette'
          'footer footer';
        grid-template-columns: 1fr 74px;
        gap: 16px;
        padding: 16px;
        background-color: #212121;
      }

      clr-color-sliders {
        grid-area: sliders;
      }

      clr-hex-input {
        grid-area: input;
      }

      clr-color-preview {
        grid-area: preview;
        margin-top: 10px;
      }

      clr-color-palette {
        grid-area: palette;
      }

      .footer {
        font-size: 12px;
      }

      .waitWarning {
        padding-bottom: 8px;
      }

      .warnIfNotFastColor {
        color: #ee441f;
      }
    `,
  ],
  providers: [
    ColorPickerStore,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ColorPickerComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ColorPickerComponent,
    },
  ],
})
export class ColorPickerComponent implements ControlValueAccessor, Validator, OnDestroy {
  subscriptions = new Subscription();
  dragImage = new Image();

  appSupportsColorPalette$: Observable<boolean>;
  isUsingFastColor$: Observable<boolean>;

  constructor(
    readonly store: ColorPickerStore,
    readonly swatchRendererService: SwatchRendererService,
  ) {
    this.appSupportsColorPalette$ = store.appSupportsColorPalette$;
    this.isUsingFastColor$ = store.isUsingFastColor$;

    this.subscriptions.add(
      this.store.color$
        .pipe(
          tap(
            color =>
              (this.dragImage.src = this.swatchRendererService.renderToDataUrl(color)),
          ),
        )
        .subscribe(),
    );
  }

  // Linter doesn't like "change" as the output name, but it's
  // very conventional with how Material components would be
  // implemented.
  //
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: Observable<Color> = this.store.change$;

  @Input() warnIfNotUsingFastColor = false;

  @Input() set color(color: Color) {
    this.store.dispatch(ColorPickerActions.writeValueFromParentComponentInput(color));
  }

  get color(): Color {
    return this.store.value;
  }

  /**
   * I'm intentionally choosing to use string values for "percent" and "bytes"
   * because I don't want to have to import the ColorPickerMode enum from the
   * component using the color picker. Using enums in Angular templates
   * is really clunky.
   */
  @Input() set mode(mode: 'percent' | 'bytes') {
    this.store.dispatch(
      ColorPickerActions.setModeFromParentComponentInput(
        mode === 'percent' ? ColorPickerMode.PERCENT : ColorPickerMode.BYTES,
      ),
    );
  }

  get mode(): 'percent' | 'bytes' {
    const mode = this.store.mode;

    return mode === ColorPickerMode.PERCENT ? 'percent' : 'bytes';
  }

  @Input() set disabled(isDisabled: boolean) {
    this.store.dispatch(
      ColorPickerActions.setDisabledStateFromParentComponentInput(isDisabled),
    );
  }

  get disabled(): boolean {
    return this.store.isDisabled;
  }

  onDragStart(event: DragEvent) {
    if (!event.dataTransfer) return;

    event.dataTransfer.setDragImage(this.dragImage, 4, 4);
    event.dataTransfer.setData('text/plain', 'source:chromahedron');
  }

  writeValue(color: Color): void {
    this.store.dispatch(ColorPickerActions.writeValueFromFormControl(color));
  }

  registerOnChange(fn: any): void {
    this.subscriptions.add(this.store.change$.subscribe(fn));
  }

  registerOnTouched(fn: any): void {
    this.subscriptions.add(this.store.touched$.subscribe(fn));
  }

  setDisabledState(isDisabled: boolean): void {
    this.store.dispatch(ColorPickerActions.setDisabledStateFromFormControl(isDisabled));
  }

  validate(): ValidationErrors | null {
    return this.store.hexInputIsValid ? null : { invalidHexString: true };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.subscriptions.add(this.store.hexInputIsValid$.subscribe(fn));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
