import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SceneApplication, SceneViewModel } from '@spog-ui/shared/models/scenes';

@Component({
  selector: 'scn-lighting-scene',
  template: `
    <div class="suiSceneHeader" (click)="showDescription = !showDescription">
      <button mat-icon-button class="suiSceneExpandButton">
        <mat-icon [class.suiSceneArrowFlipped]="showDescription"
          >keyboard_arrow_down</mat-icon
          >
        </button>
    
        <div class="suiSceneName">
          <h4>{{ scene.name }}</h4>
        </div>
    
        <div class="suiSceneActions">
          <scn-apply-scene-button
            [state]="scene.state"
            [application]="scene.application || eApplication.LIGHTING"
            (apply)="apply.emit()"
          *suiIfUserCan="[
            'ssc:siteAdmin',
            'ssc:sceneUser',
            'ssc:schedule-and-scene'
          ]"
          ></scn-apply-scene-button>
          <button
            mat-icon-button
            [matMenuTriggerFor]="sceneMenu"
            class="scnSceneMenuButton"
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:schedule-and-scene']"
            (click)="$event.stopPropagation()"
            >
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
    
      <mat-menu #sceneMenu="matMenu">
        @if (!scene.isHidden) {
          <a
            mat-menu-item
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:schedule-and-scene']"
            [routerLink]="'./' + scene.id"
            >
            Edit
          </a>
        }
        <a
          mat-menu-item
        *suiIfUserCan="[
          'ssc:siteAdmin',
          'ssc:schedule-and-scene'
        ]"
          [routerLink]="urlPrefix + '/schedule/add'"
          [queryParams]="{ sceneId: scene.id }"
          >
          Schedule
        </a>
        @if (!scene.isHidden) {
          <button
            mat-menu-item
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:schedule-and-scene']"
            (click)="duplicate.emit()"
            >
            Duplicate
          </button>
        }
        @if (!scene.isHidden) {
          <button
            mat-menu-item
            *suiIfUserCan="['ssc:siteAdmin', 'ssc:schedule-and-scene']"
            (click)="delete.emit()"
            >
            Delete
          </button>
        }
      </mat-menu>
    
      @if (showDescription) {
        <scn-scene-zone-behaviors
          [scene]="scene"
        ></scn-scene-zone-behaviors>
      }
    `,
  styles: [
    `
      :host {
        background: var(--color-background-card);
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
      }

      :host:nth-last-of-type(1) {
        border-bottom: none;
      }

      .suiSceneHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        padding: 6px;
        flex-wrap: nowrap;
      }

      .suiSceneActions {
        display: flex;
        align-items: center;
        margin: 0 0 0 6px;
      }

      .suiSceneName {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        align-items: flex-start;
      }

      h4 {
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        margin: 0;
        word-break: break-all;
      }

      a[mat-menu-item] {
        text-decoration: none;
        outline: none;
        color: var(--color-foreground-text);
      }

      button {
        line-height: initial;
      }

      .suiSceneExpandButton mat-icon {
        transition: transform 150ms;
      }

      .suiSceneExpandButton mat-icon.suiSceneArrowFlipped {
        transform: rotate(180deg);
      }

      :host [suiButton] {
        margin: 0;
      }

      @media screen and (max-width: 600px) {
        .suiSceneExpandButton {
          display: none;
        }

        .suiSceneName {
          padding-left: 16px;
        }
      }

      @media screen and (min-width: 600px) {
        .suiSceneName {
          padding: 0 0 0 18px;
        }
      }
    `,
  ],
})
export class LightingSceneComponent {
  eApplication = SceneApplication;

  @Input() scene: SceneViewModel;
  @Input() urlPrefix = '';
  @Output() apply = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() duplicate = new EventEmitter<void>();
  showDescription = false;
}
