import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: `sui-bf-fade`,
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        All lights in the zone fade to a dimming level between 0 and 100% over a period of
        time.
      </p>
      <div class="bfForm">
        <div class="bfChunk">
          <p class="bfLabel">Dim the lights to:</p>
          <sui-dimmer-control formControlName="level"></sui-dimmer-control>

          <p class="bfLabel">Over a period of:</p>
          <mat-form-field>
            <input
              matInput
              formControlName="fadeTimeSeconds"
              type="number"
              step="1"
              min="0"
              max="32767"
            />
            <span matSuffix>seconds</span>

            <mat-error>
              Must be a whole number of seconds between 0 and 32,767.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  `,
})
export class BFFadeComponent implements BehaviorForm {
  integerOnly = /^\d+$/;

  form = new UntypedFormGroup({
    level: new UntypedFormControl(0),
    fadeTimeSeconds: new UntypedFormControl(2, [
      Validators.min(0),
      Validators.max(32767),
      Validators.pattern(this.integerOnly),
      Validators.required,
    ]),
  });
}
