import { createReducer, on } from '@ngrx/store';
import {
  AddScenePageActions,
  EditScenePageActions,
  ScenesPageActions,
} from '@spog-ui/scenes/actions';

import { MapActions } from '@spog-ui/map/actions';
import { AlarmsPageActions } from '@spog-ui/alarms/actions';
import { CurrentUserActions } from '@spog-ui/current-user/auth-actions';
import { PowerActions } from '@spog-ui/power/actions';
import { IndieSensorsPage } from '@spog-ui/indie-sensors/actions';
import { ImportPageActions } from '@spog-ui/import/actions';
import { SiteControllerPage } from '@spog-ui/site-controllers/actions';
import {
  AddUtilityServicePageActions,
  EditUtilityServicePageActions,
  UtilityServicesPageActions,
} from '@spog-ui/utility-services/actions';
import {
  AddDepartmentPageActions,
  DepartmentsPageActions,
  EditDepartmentPageActions,
} from '@spog-ui/departments/actions';
import {
  AddScheduledEventPageActions,
  EditScheduledEventPageActions,
  SchedulePageActions,
} from '@spog-ui/schedule/actions';
import {
  AddTriggerPageActions,
  EditTriggerPageActions,
  TriggersPageActions,
} from '@spog-ui/triggers/actions';
import {
  AddThermostatPageActions,
  EditThermostatPageActions,
  ThermostatsPageActions,
} from '@spog-ui/thermostats/actions';
import { ClimateZonesPageActions } from '@spog-ui/climate-zones/actions';
import { PowerInsightsPageActions } from '@spog-ui/power-insights/actions';
import {
  AddEquipmentPageActions,
  EquipmentPageActions,
  EditEquipmentPageActions,
  EquipmentProfilePageActions,
} from '@spog-ui/equipment/actions';
import { EquipmentDetailsPageActions } from '@spog-ui/equipment-details/actions';
import { SceneApplication } from '@spog-ui/shared/models/scenes';
import { LightZonesPageActions } from '@spog-ui/modules/light-zones-actions';
import { ReleaseNotesPageActions } from '@spog-ui/release-notes/actions';
import { SceneViewsPageActions } from '@spog-ui/scene-views/actions';
import { ChartingPageActions } from '@spog-ui/charting/actions';
import { SiteMapPageActions } from '@spog-ui/site-selector/actions';

export interface Shape {
  product: string;
  name: string;
  isBeta?: true;
}

export const initialState: Shape = {
  product: '',
  name: '',
};

export const reducer = createReducer(
  initialState,
  on(CurrentUserActions.enterLoginPageAction, () => {
    return { product: '', name: 'Login' };
  }),
  on(MapActions.enterAction, () => {
    return { product: '', name: 'Map' };
  }),
  on(EquipmentDetailsPageActions.enterAction, () => {
    return { product: '', name: 'Map / Equipment Details' };
  }),
  on(EquipmentDetailsPageActions.leaveAction, () => {
    return { product: '', name: 'Map' };
  }),
  on(PowerActions.enterAction, () => {
    return { product: 'Power', name: 'Power Comparison' };
  }),
  on(DepartmentsPageActions.enterAction, () => {
    return { product: 'Sense', name: 'Departments' };
  }),
  on(AddDepartmentPageActions.enterAction, () => {
    return { product: 'Sense', name: 'Add Department' };
  }),
  on(EditDepartmentPageActions.enterAction, () => {
    return { product: 'Sense', name: 'Edit Department' };
  }),
  on(EquipmentPageActions.enterAction, () => {
    return { product: 'Sense', name: 'Equipment' };
  }),
  on(AddEquipmentPageActions.enterAction, () => {
    return { product: 'Sense', name: 'Add Equipment' };
  }),
  on(EditEquipmentPageActions.enterAction, () => {
    return { product: 'Sense', name: 'Edit Equipment' };
  }),
  on(AlarmsPageActions.enterAction, () => {
    return { product: 'Lighting', name: 'Alarms' };
  }),
  on(
    ScenesPageActions.enterAction,
    (state, action) =>
      ({
        [SceneApplication.CLIMATE]: {
          product: 'Climate',
          name: 'Routines',
        },
        [SceneApplication.LIGHTING]: {
          product: 'Lighting',
          name: 'Scenes',
        },
      }[action.application]),
  ),
  on(
    AddScenePageActions.enterAction,
    (state, action) =>
      ({
        [SceneApplication.CLIMATE]: {
          product: 'Climate',
          name: 'Add Routine',
        },
        [SceneApplication.LIGHTING]: {
          product: 'Lighting',
          name: 'Add Scene',
        },
      }[action.application]),
  ),
  on(
    EditScenePageActions.enterAction,
    (state, action) =>
      ({
        [SceneApplication.CLIMATE]: {
          product: 'Climate',
          name: 'Edit Routine',
        },
        [SceneApplication.LIGHTING]: {
          product: 'Lighting',
          name: 'Edit Scene',
        },
      }[action.application]),
  ),
  on(SchedulePageActions.enterAction, () => {
    return { product: '', name: 'Schedule' };
  }),
  on(AddScheduledEventPageActions.enterAction, () => {
    return { product: '', name: 'Schedule Event' };
  }),
  on(EditScheduledEventPageActions.enterAction, () => {
    return { product: '', name: 'Edit Scheduled Event' };
  }),
  on(ImportPageActions.enterAction, () => {
    return { product: '', name: 'Import' };
  }),
  on(IndieSensorsPage.enterAction, () => {
    return { product: 'Sense', name: 'Industrial Sensors' };
  }),
  on(SiteControllerPage.enterAction, () => {
    return { product: '', name: 'Site Controllers' };
  }),
  on(UtilityServicesPageActions.enterAction, () => {
    return { product: 'Power', name: 'Utility Services' };
  }),
  on(AddUtilityServicePageActions.enterAction, () => {
    return { product: 'Power', name: 'Add Utility Service' };
  }),
  on(EditUtilityServicePageActions.enterAction, () => {
    return { product: 'Power', name: 'Edit Utility Service' };
  }),
  on(ThermostatsPageActions.enterAction, () => {
    return { product: 'Climate', name: 'Devices & Zones' };
  }),
  on(ClimateZonesPageActions.enterAction, () => {
    return { product: 'Climate', name: 'Devices & Zones' };
  }),
  on(AddThermostatPageActions.enterAction, () => {
    return { product: 'Climate', name: 'Add Thermostat' };
  }),
  on(EditThermostatPageActions.enterAction, () => {
    return { product: 'Climate', name: 'Edit Thermostat' };
  }),
  on(TriggersPageActions.enterAction, () => {
    return { product: 'Sense', name: 'Triggers' };
  }),
  on(AddTriggerPageActions.enterAction, () => {
    return { product: '', name: 'Add Trigger' };
  }),
  on(EditTriggerPageActions.enterAction, () => {
    return { product: '', name: 'Edit Trigger' };
  }),
  on(PowerInsightsPageActions.enterAction, () => {
    return { product: 'Power', name: 'Insights', isBeta: true as const };
  }),
  on(EquipmentProfilePageActions.enterAction, () => {
    return { product: 'Map', name: 'Equipment Profile' };
  }),
  on(LightZonesPageActions.enterAction, () => {
    return { product: 'Lighting', name: 'Zones' };
  }),
  on(ReleaseNotesPageActions.enterAction, () => {
    return { product: '', name: 'Release Notes', isBeta: true as const };
  }),
  on(ChartingPageActions.enterAction, () => {
    return { product: '', name: 'Charting and Visualization' };
  }),
  on(SceneViewsPageActions.enterAction, () => {
    return { product: '', name: 'Scene Views' };
  }),
  on(SiteMapPageActions.enterAction, () => {
    return { product: '', name: 'Select a Site' };
  }),
);

export const selectProduct = (state: Shape) => state.product;
export const selectName = (state: Shape) => state.name;
export const selectIsBeta = (state: Shape) => state.isBeta;
