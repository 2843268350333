import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  SubscriptionBannerComponent,
  SafeurlPipe,
} from './subscription-banner.component';

@NgModule({
    imports: [CommonModule, SubscriptionBannerComponent, SafeurlPipe],
    exports: [SubscriptionBannerComponent],
})
export class SubscriptionBannerComponentModule {}
