import { Interval, SourceTypeEnum } from '@spog-ui/graphql/types';
import { ComplexValueModel, SourceInternalModel } from './source.model';
import { DateTime } from 'luxon';

export type DataList = {
  name: string;
  units: string;
  values: [number, number][];
};

export interface SelectedSourceInternalModel {
  id: string;
  name: string;
  type: SourceTypeEnum;
  readingUnits: string;
  siteId: string;
  complexValues: ComplexValueModel[];
  data: DataList[];
}

export function toSelectedSourceInternalModelFromSourceInternal(
  source: SourceInternalModel,
  buckets: any[],
): SelectedSourceInternalModel {
  const data: DataList[] = [];

  if (source.complexValues.length > 0) {
    source.complexValues.forEach(complexValue => {
      data.push({
        units: complexValue.units,
        values: [],
        name: complexValue.name,
      });
    });
  } else {
    data.push({
      units: source.readingUnits,
      values: [],
      name: 'default',
    });
  }

  // Transform data and put into correct list
  buckets.forEach(bucket => {
    if (source.complexValues.length > 0) {
      // find the correct list
      data.forEach(list => {
        // Things like energy cost only have hourly data points, and so
        // buckets will come back as undefined.  This filters those out
        if (bucket.complexValue[list.name] !== undefined) {
          list.values.push([
            DateTime.fromISO(bucket.timestamp, { setZone: true }).toMillis(),
            bucket.complexValue[list.name],
          ]);
        }
      });
    } else {
      data[0].values.push([
        DateTime.fromISO(bucket.timestamp, { setZone: true }).toMillis(),
        bucket.value,
      ]);
    }
  });

  return {
    id: source.id,
    name: source.name,
    type: source.type,
    readingUnits: source.readingUnits,
    siteId: source.siteId,
    data,
    complexValues: source.complexValues,
  };
}

export function getYAxisId(units: string) {
  return `${units}-yAxis`;
}

export function deriveAxes(sources: SelectedSourceInternalModel[]) {
  let opposite = false;

  return sources.reduce((axesList: any[], source) => {
    // Do we not already have an axis with this ID?
    source.data.forEach(dataList => {
      if (!axesList.find(axis => axis.id === getYAxisId(dataList.units))) {
        // Then add one.
        axesList.push({
          id: getYAxisId(dataList.units),
          labels: {
            format: `{value:.1f}`,
          },
          title: {
            text: dataList.units,
            // These settings move the title to the top, rotate it to
            // horizontal, and offset to to position over the axis
            align: 'high',
            rotation: 0,
            y: 0,
            offset: 25,
            textAlign: 'center',
          },
          opposite,
        });

        opposite = !opposite;
      }
    });

    return axesList;
  }, []);
}

export function deriveSeries(sources: SelectedSourceInternalModel[]): any[] {
  const seriesList: any[] = [];

  sources.forEach(source => {
    source.data.forEach(dataList => {
      seriesList.push({
        id: `${source.id}-${dataList.name}-series`,
        name: source.name.replace('Assets', 'Equipment'),
        data: dataList.values,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: ` ${dataList.units}`,
        },
        type: 'spline',
        yAxis: getYAxisId(dataList.units),
      });
    });
  });

  return seriesList;
}

function intervalToTitleSegment(interval: Interval): string {
  switch (interval) {
    case Interval.FIFTEEN_MINUTES:
      return ', 15-Minute Intervals';
    case Interval.HOUR:
      return ', 1-Hour Intervals';
    case Interval.DAY:
      return ', 1-Day Intervals';
    case Interval.WEEK:
      return ', 1-Week Intervals';
    case Interval.MONTH:
      return ', 1-Month Intervals';
    default:
      return '';
  }
}

export function deriveTitle(
  dateRange: [DateTime, DateTime],
  interval: Interval,
  useLongTitle: boolean,
): string {
  return `${dateRange[0].toLocaleString(
    DateTime.DATE_SHORT,
  )} - ${dateRange[1].toLocaleString(DateTime.DATE_SHORT)}${
    useLongTitle ? intervalToTitleSegment(interval) : ''
  }`;
}
